import io from 'socket.io-client';

let manifest = {};

let urlParams = new URLSearchParams(window.location.search);

let origin = urlParams.get('origin');
let tokenJwt = urlParams.get('tokenJwt');
let client_id = urlParams.get('client_id');

let restParams = window.location.search;

let postPromises = {};

const nodeTypes = {
  FOLDER: '0',
  FILE: '1',
  BRANCH: '2',
};

const showToast = (message) => {
  if(!!message) {
    const toasts = document.querySelector('crowdin-toasts');
    if(toasts && toasts.pushToasts) {
      toasts.pushToasts([message]);
    } else {
      setTimeout(() => {
        showToast(message);
      }, 1000);
    }
  }
};

const catchRejection = (e, message = undefined) => {
  // here the right place to console.log what goes wrong
  // console.log('err -------------->', e);
  showToast((e || {}).error || message);
};

if(!origin || !client_id || !tokenJwt) {
  catchRejection(null, 'Request has no origin data (clientId, tokenJWT)');
}

(function getManifest() {
  fetch('/manifest.json')
    .then((response) => {
      return checkResponse(response);
    })
    .then((res) => {
      manifest = res;
      document.title = res.name;
      let el = document.querySelector('.i_w h1');
      if(!!el) {
        el.innerText = manifest.name;
      }
    })
    .catch(e => {
      catchRejection(e, 'Can\'t fetch manifest.json');
    });
})();

let socket = io();
socket.on('message', (event) => {
  const filesComponent = document.querySelector('crowdin-files-component');
  const el = document.querySelector("crowdin-async-progress");
  if(event.loadingFiles){
    setComponentData(event.files, 'pushFilesData', 'is-loading');
    el.pushJobs(event.job);
    filesComponent && filesComponent.setAttribute('is-loading', !event.finishedLoading);
  } else {
    filesComponent && filesComponent.setAttribute('is-loading', false);
    showToast(event.message || event);
    event.error && event.e && console.error(event.e);
  }
});

socket.on('connect', () => {
  try {
    let decoded = parseJwt(tokenJwt);
    socket.emit('createTunnel', {
      tunnelId: `${decoded.domain || decoded.context.organization_id}__${decoded.aud}__${decoded.context.project_id}`,
    });
  } catch(e) {
    console.log('Can\'t establish web-socket connection');
  }
});

if(!window.Promise) {
  window.Promise = Promise;
}

const checkResponse = (response) => {
  return new Promise((resolve, reject) => {
    if(response.status === 204) {
      return resolve();
    }
    response.json()
      .then(res => {
        if(![200, 201].includes(response.status)) {
          reject(res);
        } else {
          resolve(res);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const parentWindowPostMessage = (data) => {
  return new Promise((resolve, reject) => {
    window.parent.postMessage(
      JSON.stringify(data),
      origin
    );
    let tId = setTimeout(() => {
      reject();
    }, 10000);
    postPromises[data.uid] = {resolve: resolve, timer: tId};
  });
};

const checkOrigin = () => {
  return new Promise((resolve, reject) => {
    try {
      let tokenData = parseJwt(tokenJwt);
      if(tokenData.exp < parseInt(new Date().getTime() / 1000)) {

        // request for new token
        parentWindowPostMessage({
          client_id: client_id,
          command: 'token',
          uid: new Date().getTime(),
          data: {}
        })
          .then((eventData) => {
            restParams = `?origin=${origin}&client_id=${client_id}&tokenJwt=${eventData.tokenJwt}`;
            resolve(restParams);
          })
          .catch(() => reject());

      } else {
        resolve(restParams);
      }
    } catch(e) {
      reject();
    }
  });
};

const handleMessage = (event) => {
  if(event.data === 'reload') {
    // isInstalled();
  } else {
    let eventData = {};
    try {
      eventData = JSON.parse(event.data);
    } catch(e) {

    }
    if(postPromises[eventData.uid]) {
      clearTimeout(postPromises[eventData.uid].timer);
      postPromises[eventData.uid].resolve(eventData.data);
    }
  }
};

window.addEventListener('message', handleMessage);

const setComponentData = (res, key, attr) => {
  let el = document.querySelector('crowdin-files-component');
  if(el[key]) {
    el[key](res);
    el.setAttribute(attr, false);
  } else {
    setTimeout(() => {
      setComponentData(res, key, attr)
    }, 100);
  }
};

const checkStatus = () => {
  return checkOrigin()
    .then(restParams => {
      return fetch('/status' + restParams)
    })
    .then((response) => {
      return checkResponse(response);
    })
    .catch(e => {
      catchRejection(e, 'Can\'t fetch integration status');
    });
};

const getCrowdinFiles = () => {
  let el = document.querySelector('crowdin-files-component');
  el && el.setAttribute('is-loading', true);
  checkOrigin()
    .then(restParams => {
      return fetch('/crowdin-data' + restParams)
    })
    .then((response) => {
      return checkResponse(response);
    })
    .catch(e => {
      el && el.setAttribute('is-loading', false);
      catchRejection(e, 'Can\'t fetch Crowdin files');
    });
};

const getCrowdinFile = (id) => {
  return checkOrigin()
    .then(restParams => {
      return fetch(`/crowdin-file/${id}` + restParams)
    })
    .then((response) => {
      return checkResponse(response);
    })
    .catch(e => {
      catchRejection(e, 'Can\'t fetch Crowdin files');
    });
};

const updateCrowdinFile = (fileId, fileData, fileName) => {
  return checkOrigin()
    .then(restParams => {
      return fetch('/upload-to-crowdin' + restParams, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({fileId, fileData, fileName})
      })
    })
    .then((response) => {
      return checkResponse(response);
    })
    .then((res) => {
      showToast('Done');
      return new Promise(resolve => resolve(res))
    })
    .catch(e => {
      catchRejection(e, 'Can\'t fetch Crowdin files');
    });
};

export {
  manifest,
  nodeTypes,
  showToast,
  checkOrigin,
  checkStatus,
  postPromises,
  checkResponse,
  getCrowdinFile,
  catchRejection,
  getCrowdinFiles,
  updateCrowdinFile,
}
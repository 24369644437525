import React, { Component } from 'react';

class FileSelector extends Component {

    componentDidMount() {
        document.body.addEventListener('crowdinFilesSelectedChanged', this.props.setFileId);
    }

    componentWillUnmount() {
        document.body.removeEventListener('crowdinFilesSelectedChanged', this.props.setFileId);
    }

    render() {
        return (
            <>
                <crowdin-h3>Select the file</crowdin-h3>
                <br/>
                <crowdin-card without-p is-white>
                    <crowdin-files-component is-clickable id="file-selector">
                    </crowdin-files-component>
                </crowdin-card>
            </>
        )
    }
}

export default FileSelector;
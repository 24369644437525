import React, {Component, Fragment} from 'react';
import {getCrowdinFile, updateCrowdinFile} from './helpers';

import MonacoEditor from 'react-monaco-editor';

const fileFormats = {
  xml: 'xml',
  json: 'json',
  html: 'html',
  android: 'xml',
  js: 'javascript',
};

class FileEditor extends Component {

  state = {
    fileData: null,
    fileInfo: {},
    isSaving: false,
  };

  componentDidMount() {
    getCrowdinFile(this.props.fileId)
      .then(res => {
        let fileData = null;
        if(typeof res.data !== 'string'){
          fileData = JSON.stringify(res.data, null, '  ');
        } else {
          fileData = res.data;
        }
        this.setState({fileData, fileInfo: res});
      })
  }

  saveFile = () => {
    this.setState({isSaving: true}, () => {
      updateCrowdinFile(this.props.fileId, this.state.fileData, this.state.fileInfo.name)
        .finally(() => this.setState({isSaving: false}));
    });
  };

  render() {
    const language = fileFormats[this.state.fileInfo.type] || this.state.fileInfo.type;
    return (
      <div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <crowdin-button title="Go back" icon onClick={this.props.setFileId}>keyboard_backspace</crowdin-button>&nbsp; &nbsp;
          <crowdin-h3>{this.state.fileInfo.name || ''}</crowdin-h3>
          <div style={{marginLeft: 'auto'}}>
            <crowdin-button is-loading={this.state.isSaving} primary onClick={this.saveFile}>
              Save
            </crowdin-button>
          </div>
        </div>
        <br/>
        <crowdin-card is-white without-p>
        {!this.state.fileData &&
          <Fragment>
            <br/>
            <center style={{verticalAlign: 'middle'}}>
              <crowdin-progress-indicator/>
            </center>
            <br/>
          </Fragment>
        }
        {!!this.state.fileData &&
          <MonacoEditor
            width="100%"
            height="calc(100vh - 140px)"
            language={language}
            theme="vs-light"
            value={this.state.fileData}
            options={{
              tabSize: 2,
              insertSpaces: true,
              wrapLineLength: 5,
              indentInnerHtml: true,
              preserveNewLines: true,
              maxPreserveNewLines: 5,
              indentHandlebars: true,
              endWithNewline: true,
              wrapAttributes: 'auto',
              scrollBeyondLastLine: false,
              renderFinalNewline: true,
              wordWrap: 'on',
              wrappingIndent: 'same',
            }}
            onChange={fileData => this.setState({fileData})}
          />
        }
        </crowdin-card>
      </div>
    )
  }
}

export default FileEditor;
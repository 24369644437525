import React, { Component, Fragment } from 'react';
import './App.css';

import FileSelector from './FileSelector.js';
import { getCrowdinFiles, nodeTypes, checkStatus } from './helpers';
import FileEditor from './FileEditor.js';

class App extends Component {

  componentDidMount(){
    checkStatus()
      .then(res => {
        if(!res || !res.isInstalled){
          this.setState({isInstalled: false});
        } else {
          getCrowdinFiles();
        }
      });
  }

  state = {
    fileId: null,
    isInstalled: true,
  };

  setFileId = (e) => {
    if(((e.detail || {}).selected || []).length === 1){
      const data = ((((e || {}).detail || {}).selected || [])[0] || {});

      data.node_type === nodeTypes.FILE && this.setState({fileId: data.id });
    } else {
      this.setState({fileId: null});
    }
  };

  render() {
    return (
      <div className="App">
        <div class='i_w'>
          {!this.state.isInstalled &&
          <p>Looks like integration not installed yet, or no data origin provided</p>
          }
          {this.state.isInstalled &&
            <Fragment>
              <div
                style={{height: this.state.fileId ? '0' : 'auto', overflow: 'hidden'}}
              >
                <FileSelector setFileId={this.setFileId} />
              </div>
              {!!this.state.fileId &&
                <FileEditor fileId={this.state.fileId} setFileId={this.setFileId} />
              }
            </Fragment>
          }
        </div>
        <crowdin-toasts></crowdin-toasts>
        <crowdin-async-progress></crowdin-async-progress>
      </div>
    );
  }
}

export default App;
